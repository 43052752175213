<template>
  <q-page padding>
    <q-form
      class="row justify-center"
      @submit.prevent="handleResetPassword"
    >
      <h5 class="col-12 text-center">Redefinição de senha</h5>
      <div class="col-md-4 col-sm-6 col-xs-10  q-gutter-y-md">
        <q-input
          v-model="password"
          label="Nova Senha"
          :type="isPwd ? 'password' : 'text'"
          lazy-rules
          :rules="[ val => val && val.length >= 8 || 'Favor informe uma senha valida!']"
        >
          <template v-slot:prepend>
            <q-icon name="lock" />
          </template>
          <template v-slot:append>
            <q-icon
              :name="isPwd ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd = !isPwd"
            />
          </template>
        </q-input>
        <div
          class="g-recaptcha"
          data-sitekey="6LdkwCMeAAAAAKuGHbRLGqNDCnYCW-1HDvsWRrjB"
        ></div>
        <div class="full-width q-mt-md">
          <q-btn
            label="Redefinir"
            class="full-width"
            color="primary"
            type="submit"
            icon="save"
          />
        </div>
        <div class="full-width">
          <q-btn
            label="Voltar"
            class="full-width"
            flat
            :to="{name: 'login'}"
            icon="arrow_back"
          />
        </div>
      </div>

    </q-form>

  </q-page>
</template>
<script>
import { defineComponent, ref } from 'vue'
import useAuthUser from '../composables/UseAuthUser'
import { useRouter, useRoute } from 'vue-router'
import useNotify from '../composables/UseNotify'

export default defineComponent({
  name: 'PageResetPassword',

  setup () {
    const router = useRouter()
    const route = useRoute()
    const token = route.query.token

    const { notifyError, notifySuccess } = useNotify()

    const { resetPassword } = useAuthUser()

    const password = ref('')

    const isPwd = ref(true)

    const handleResetPassword = async () => {
      try {
        await resetPassword(token, password.value)
        notifySuccess('Redefinição de senha realizada com sucesso!')
        router.push({ name: 'login' })
      } catch (error) {
        notifyError(error.message)
      }
    }

    return { password, isPwd, handleResetPassword }
  }
})
</script>
